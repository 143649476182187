import React from 'react';

function Footer() {
  return (
    <div style={{
      backgroundColor: 'black',
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1 style={{
        color: 'white',
        fontSize: '16px',
        margin: '0',
        lineHeight: '1.5'
      }}>
        © 2024 Shouryanga Technologies LLP. All Rights Reserved.
      </h1>
    </div>
  );
}

export default Footer;