import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DownloadGuide from "./DownloadGuide";
import './DownloadScreen.css';

const DownloadScreen = () => {
  const [countdown, setCountdown] = useState(5); 
  const [isDownloading, setIsDownloading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsDownloading(true);
      const downloadLink = document.createElement('a');
      downloadLink.href = "/app.apk"; 
      downloadLink.setAttribute('download', 'CricketCombat.apk');
      downloadLink.click();
    }
  }, [countdown]);

  const handleCloseClick = () => {
    navigate('/');
  };

  return (
    <div className="download-screen">
      <div className="navbar-download">
        <h1 className="navbar-title-download">Cricket Combat</h1>
        <button
          onClick={handleCloseClick}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '24px',
            border: 'none',
            background: 'transparent',
            color: "white",
            cursor: 'pointer',
          }}
        >
          &times;
        </button>
      </div>

      <h3 className="downloading-state">
        {isDownloading
          ? "Downloading..."
          : `Download starts in ${countdown} seconds`}
      </h3>

      <h3 className="how-to">
        How to Install <span style={{ color: 'red' }}>Cricket Combat</span> App?
      </h3>

        <DownloadGuide instructionNumber={1} instruction={"Tap 'Ok' to download the app"} />
        <DownloadGuide instructionNumber={2} instruction={"Open the downloaded file to start installation"} />
        <DownloadGuide instructionNumber={3} instruction={"Approve the installation from unknown sources"} />
        <DownloadGuide instructionNumber={4} instruction={"Tap install to complete the installation"} />
    </div>
  );
};

export default DownloadScreen;