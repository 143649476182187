import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h2><span style={{ fontWeight: 'bold' }}>Step into the World of Cricket Combat!</span></h2>
      <p>
        Get ready to relive the thrill of the cricket card games you loved as a kid, but now with an exciting digital twist! <span style={{ fontWeight: 'bold' }}>Cricket Combat</span> brings back the intensity and fun of classic card games, blending nostalgia with a strategic, skill-based gameplay experience. Battle it out with friends or rivals and prove you're the ultimate cricket strategist!
      </p>
      <h2>How to Play?</h2>
      <p>
        Each player is dealt a set of cricket cards featuring legendary players with unique stats in batting, bowling, and fielding. The game is turn-based, challenging you to use strategy and your cricket knowledge to outplay your opponents in competitive rounds.
      </p>
      <p>
        Whether you're a die-hard cricket fan or just looking to rekindle childhood memories, <span style={{ fontWeight: 'bold' }}>Cricket Combat</span> is a skill-driven game where every decision matters. Strategize your moves and dominate the field in this thrilling card battle!
      </p>
    </div>
  );
}

export default About;