import '@fortawesome/fontawesome-free/css/all.min.css';
import { default as React, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import DownloadScreen from './components/DownloadScreen';
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";


const App = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const androidButtonRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (androidButtonRef.current) {
      observer.observe(androidButtonRef.current);
    }

    return () => {
      if (androidButtonRef.current) {
        observer.unobserve(androidButtonRef.current);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainScreen
              isButtonVisible={isButtonVisible}
              androidButtonRef={androidButtonRef}
            />
          }
        />
        <Route path="/download" element={<DownloadScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

const MainScreen = ({ androidButtonRef, isButtonVisible }) => {
  return (
    <div className='main-screen'> 
       <div className="navbar-home">
        <h1 className="navbar-title-home">Cricket Combat</h1>
      </div>
      <div className="header-content">
        <button className="download-button" ref={androidButtonRef}>
          <img src="/android_icon.png" width="20px" alt="Android Icon" className="button-icon" />
          Download
        </button>
      </div>

      <About />
      <FAQ />

      <Footer />
      <div className="spacer"></div>
      {!isButtonVisible && (
        <div className="fixed-download-box">
          <Link to="/download" className="fixed-download-link" style={{ width: '100%', height: '100%', textDecoration: 'none' }}>
            <button className="fixed-download-button">
              DOWNLOAD APP
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default App;